import * as React from "react";

import { cn } from "@/lib/shadcn-utils";
import { useField } from "remix-validated-form";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string,
  label?: string,
  placeholder?: string,
  helperText?: string
  passwordMode?: boolean,
}

const Input = React.forwardRef<HTMLInputElement, InputProps>((
    {
      className,
      type,
      name,
      label,
      placeholder,
      helperText,
      ...props
    }, ref) => {
  const { error, getInputProps } = useField(name);

    return (
      <div className={`${className ?? ""}`}>
        {label &&
          <label className={"text-sm text-gray-700 dark:text-gray-300 font-semibold"} htmlFor={name}>{label}</label>
        }
        <input {...props}
               type={type} name={name} ref={ref}
               className={cn(
                 "flex w-full rounded-md border border-gray-200 bg-white px-3 py-1.5 text-gray-700 dark:text-white text-sm ring-offset-white file:border-0 file:bg-transparent transition file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-700 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-800 dark:bg-gray-950 dark:ring-offset-gray-950 dark:placeholder:text-gray-400 dark:focus-visible:ring-gray-300",
                 className,
                 {
                   "mt-1": label
                   // "border-red-500": error
                 }
               )}
               {...getInputProps({
                 id: name,
                 autoComplete: "off",
                 placeholder
               })}
        />
        {helperText &&
          <p className={"mt-1 text-gray-500 text-sm"}>{helperText}</p>
        }
        {error &&
          <span className="mt-1 text-red-500">{error}</span>
        }
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
